import React, { useEffect, useState } from 'react';

const Counter = () => {
    const [count, setCount] = useState(0);

    useEffect(() => {
        document.title = `You clicked ${count} times`;

        let letters = ['a','b','c'];
        letters.map(letter => console.log(letter));
    });

    return (
        <div>
            <p>You clicked {count} times</p>
            <button onClick={() => setCount(count +1)}>
                Click me
            </button>
        </div>
    );
}

export default Counter;
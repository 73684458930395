import React, {useContext} from 'react';
import { TodoContext } from '../Contexts/TodoContext';

const TodoList = () => {
    const { todos, dispatch } = useContext(TodoContext);
    return (
    <div>
        <ul>
            {todos.map(todo => {
              return (
                <li onClick={() => dispatch({type:'REMOVE_TODO', id: todo.id})} key={todo.id}>{todo.title}</li>
              )
            })}
        </ul>
    </div>
    );

}

export default TodoList
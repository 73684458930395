import React, { createContext, useReducer} from 'react';
import {TodoReducer} from '../Reducers/TodoReducer'

export const TodoContext = createContext();

const TodoContextProvider = (props) => {
    const [todos, dispatch] = useReducer(TodoReducer, [], () => {
        return [
            {id:1, title:"Start"},
            {id:2, title:"Do"},
            {id:3, title:"End"}
        ];
    });

    return (
        <TodoContext.Provider value={{todos, dispatch}}>
            {props.children}
        </TodoContext.Provider>
    )
}

export default TodoContextProvider
